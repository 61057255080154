const ProjectsList = [
  {
    where: 'Toronto Metropolitan University',
    name: 'Laser Tag Robot',
    date: 'Sep 2024 - Present',
    desc: 'An autonomous mobile robot with the ability to navigate a laser tag field, follow a path, avoid obstacles and edges, find targets, and shoot static and dynamic targets while avoiding being shot itself.',
    tags: ['Autonomous Mobile Robotics', 'Arduino'],
    buttons: []
  },
  {
    where: 'Toronto Metropolitan University + Personal Project',
    name: 'AI Projects Collection',
    date: 'Nov 2023 - Present',
    desc: 'A growing collection of AI projects done in Prolog solving key AI problems and techniques, including knowledge representation and reasoning, constraints satisfaction, search, natural language understanding, and planning.',
    tags: ['Prolog', 'Artificial Intelligence'],
    buttons: [
      {
        view: 'Full Project Description',
        link: 'https://github.com/Ghazalmir/Project-Descriptions/blob/main/READMEs/AI-Projects.md'
      }
    ]
  },
  {
    where: 'Toronto Metropolitan University',
    name: 'MetMarket',
    date: 'Jan-Apr 2024',
    desc: 'A marketplace website for TMU students made with ReactJS, HTML, CSS and Bootstrap, Javascript, MySQL, NodeJS, and ExpressJS.',
    tags: ['Web Development', 'ReactJS', 'Express.js', 'JavaScript', 'HTML & CSS', 'MySQL'],
    buttons: [
      {
        view: 'GitHub',
        link: 'https://github.com/Ghazalmir/MetMarket'
      }
    ]
  },

  {
    where: 'Toronto Metropolitan University',
    name: 'Ice Cream Factory Database',
    date: 'Sep-Nov 2023',
    desc: 'A database made with SQL, Unix, and Python for an imaginary ice cream factory. The final report includes the ER diagram, schema designs, database constructions and querying, normalization to BCNF, RA notation, and UI implementation. (Academic Course: CPS 510 | Grade: 100%)',
    tags: ['SQL', 'Unix', 'Python', 'Database'],
    buttons: []
  },
  {
    where: 'Toronto Metropolitan University',
    name: 'FPS Game',
    date: 'Nov 2023',
    desc: 'A first-person shooting game made with openGL and Glut in C++ with full movement range for the player, 3 view types, enemies with random movements, and special effects. (Academic Course: CPS 511 | Grade: 100%)',
    tags: ['OpenGL', 'C++', 'GLUT', 'Texture Mapping', 'Game Development'],
    buttons: [
      {
        view: 'Full Project Description',
        link: 'https://github.com/Ghazalmir/Project-Descriptions/blob/main/READMEs/FPS-Game.md'
      }
    ]
  },
  {
    where: 'Toronto Metropolitan University',
    name: 'Interactive Shape Modeller',
    date: 'Oct 2023',
    desc: 'A 3D shape modeler made with openGL and Glut in C++ with the ability to generate sweep objects, full camera rotation and elevation, and two different views. (Academic Course: CPS 511 | Grade: 100%)',
    tags: ['OpenGL', 'GLUT', 'Shape Modelling', '3D Modeller'],
    buttons: [
      {
        view: 'Full Project Description',
        link: 'https://github.com/Ghazalmir/Project-Descriptions/blob/main/READMEs/Shape-Modeller.md'
      }
    ]
  },
  {
    where: 'Personal Project',
    name: 'Portfolio Website',
    date: 'Jan 2022',
    desc: 'A personal portfolio website made with ReactJS, HTML, CSS, and JavaScript for showcasing my skills, projects, and experience.',
    tags: ['ReactJS', 'HTML/CCSS', 'JavaScript', 'Web Development'],
    buttons: [
      {
        view: 'GitHub',
        link: 'https://github.com/Ghazalmir/Portfolio'
      }
    ]
  },
  {
    where: 'Toronto Metropolitan University',
    name: 'Zara Mobile App UI/UX Research And Redesign',
    date: 'Winter 2022',
    desc: 'A group research and redesign project for Zara’s mobile application’s user interface completed in five phases: a proposal document, user study, questionnaire design, research and data analytics, and low-fidelity and high-fidelity prototypes.',
    tags: ['UX/UI Design', 'Research', 'Prototype', 'Figma'],
    buttons: []
  },
  {
    where: 'Toronto Metropolitan University',
    name: 'Lingo',
    date: 'Dec 2021',
    desc: 'A C program to play a game called LINGO (much like BINGO), done as an assignment for CPS393 (Unix, C, and C++) to demonstrate understanding of the course material. (Academic Course: CPS 393 | Grade: 100%)',
    tags: ['C', 'Memory Allocations', 'Structures', 'Linked-lists'],
    buttons: [
      {
        view: 'Full Project Description',
        link: 'https://github.com/Ghazalmir/project-descriptions/blob/main/READMEs/Lingo.md#lingo-c---shell-scripting--fall-2021'
      }
    ]
  },
  {
    where: 'Toronto Metropolitan University',
    name: 'ReactJS and Express.js Guide (Website)',
    date: 'Nov-Dec 2021',
    desc: 'A ReactJS and Express.js tutorial website (including a Weather Forecast Application as a sample program) made with made with ReactJS, Express.js, HTML, CSS, and Javascript as the final project of CPS530 (Web Systems Development) at Toronto Metropolitan University. (Academic Course: CPS 530 | Grade: 100%)',
    tags: ['ReactJS', 'Express.js', 'JavaScript', 'HTML & CSS','Web Development'],
    buttons: [
      {
        view: 'Project Website',
        link: 'https://cps530-final-project.netlify.app/demo'
      },
      {
        view: 'GitHub',
        link: 'https://github.com/Ghazalmir/React-and-Express-Guide'
      },
      
    ]
    
  },
  {
    where: 'Toronto Metropolitan University',
    name: 'Linux Bingo',
    date: 'Oct 2021',
    desc: 'A bash program to play a game called LINGO (much like BINGO), done as an assignment for CPS393 (Unix, C, and C++) to demonstrate understanding of the course material. (Academic Course: CPS 393 | Grade: 100%)',
    tags: ['Bash', 'Unix/Linux', 'Shell Scripting'],
    buttons: [
      {
        view: 'Full Project Description',
        link: 'https://github.com/Ghazalmir/project-descriptions/blob/main/READMEs/Lingo.md#lingo-c---shell-scripting--fall-2021'
      }
    ]
  },
  {
    where: 'Personal Project',
    name: 'Campifier',
    date: 'Summer 2021',
    desc: 'A campground suggestion website made with HTML, CSS and Bootstrap, Javascript, NodeJS, and ExpressJS; Database managed with MongoDB and Mongoose with cloud storage; Full, secure user authentication and authorization; Fully implemented cookies and sessions; Managed and planned using the Agile methodologies as the final cumulative project for a Web Development bootcamp.',
    tags: ['HTML', 'CSS & Bootstrap', 'JavaScript','NodeJS', 'ExpressJS', 'MongoDB', 'Web Development','Agile Project Management'],
    buttons: [
      {
        view: 'GitHub',
        link: 'https://github.com/Ghazalmir/Campifier'
      }
    ]
  },
  {
    where: 'Personal Project',
    name: 'Collection of 2D and 3D Games',
    date: 'Summer 2021 - Present',
    desc: 'A collection of adaptations of 4 classic styles of 2D and 3 classic types of 3D video games; Made using C#, Unity, Blender, Adobe Photoshop, and Gimp; Managed and planned using the Agile methodologies.',
    tags: ['C#', 'Unity', 'Blender', 'Adobe Photoshop', 'Animation'],
    buttons: []

  },
  {
    where: 'Toronto Metropolitan University',
    name: 'Flight Reservation System',
    date: 'Spring 2021',
    desc: 'The Flight Reservation System is a command-line program that models flights departing from the Pearson Airport during a single day. Initially done as the final project for a university course (CPS 209) and expanded over time, this program uses OOP, collections, file I/O and exceptions, objects and classes, inheritance, and interfaces in Java. (Academic Course: CPS 209 | Grade: 100%)',
    tags: ['java', 'OOP', 'inheritance'],
    buttons: [
      {
        view: 'Full Project Description',
        link: 'https://github.com/Ghazalmir/project-descriptions/blob/main/READMEs/Flight-Reservation-System.md#flight-reservation-system'
      }
    ]
  },
  {
    where: 'Toronto Metropolitan University',
    name: 'Persian-English Translator',
    date: 'Fall 2020',
    desc: 'Designed and made using functional programming and collections in Python in order to build a functional software intended for bilingual communication. (Academic Course: CPS 109 | Grade: 100%)',
    tags: ['Python', 'Functional Programming', 'Collections'],
    buttons: []
  },
  {
    where: 'Toronto Metropolitan University',
    name: 'Caesar Cipher Bot',
    date: 'Fall 2020',
    desc: 'A Ceaser Cipher program made with Python for encryption and decryption of messages with a small tutorial on the inceryption method itself.',
    tags: ['Python', 'Functional Programming', 'Collections'],
    buttons: []
  }
]

  export  { ProjectsList };